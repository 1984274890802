import React from "react";
import YouTube from 'react-youtube';

import Header from '../../header';
import Footer from '../../footer';

import PhotoGallery from '../../components/PhotoGallery';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import ApplicationFormGoalkeepersDay from '../../components/forms/ApplicationFormGoalkeepersDay';
import {getCampIntroduction, loadCampsDataAndRender, loadPhotoGalleryImages, getPhotosFromGallery} from './CampsUtil';

const eventName = "Den Brankářů";

export default class GoalkeepersDay extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      photoUrl: undefined,
      campsData: [],
      photoGallery: [],
      isLoading: true,
    };
  }

  componentDidMount(){
      loadCampsDataAndRender("goalkeepers_day_camps", this);
      loadPhotoGalleryImages("goalkeepers_day_camps", eventName, this);
  }

  render() {
    const {campsData, isLoading, isIntroLoading, photoUrl, photoGallery} = this.state;
    const hideHeaderAndFooter = this.props.hideHeaderAndFooter;

    let campIntroduction = undefined;
    let isPhotoGalleryLoading = true;

    if(campsData.length > 0) {
      campIntroduction = campsData[0];
      let numberOfGalleriesToLoad = campIntroduction.number_of_galleries !== undefined ? campIntroduction.number_of_galleries : 0;
      isPhotoGalleryLoading = (photoGallery.length < numberOfGalleriesToLoad);
      console.log("Number of photoGallery to load: " + numberOfGalleriesToLoad);
      console.log("Loaded photoGalleries: " + photoGallery.length);
    }

    const opts = {
     height: '450',
     width: '100%',
     playerVars: { // https://developers.google.com/youtube/player_parameters
       autoplay: 0
     }
   };

   let loading = isLoading || isIntroLoading || isPhotoGalleryLoading

   return loading ? (
           <LoadingSpinner loadingState={loading} />
       ) : (
      <div>
            {hideHeaderAndFooter ? '' : <Header />}
		        <div className="view textArticle camps">
              {getCampIntroduction(campIntroduction, photoUrl)}

              <p>
                Přihlas se rychle, bereme 20 prvních z každé kategorie <a href='https://just4keepers.dtap.cz/register/?link=11'>(přihláška zde)</a>
              </p>

              <p>
                Soutěžní kategorie: 2016 a mladší, 2014 - 2015, 2012 - 2013, 2010 - 2011, 2009 a starší.
              </p>

              <p>
                Roztrub to do světa! Při této příležitosti přivítáme také ostatní milovníky zábavy a sportu. Jako doprovodný program bude možné zkusit si a zasoutěžit v různých sportovních disciplínách v bláznivém olympijském parku Jfamily.
              </p>

              <h4> <a href='https://just4keepers.dtap.cz/register/?link=11'>Online přihláška - klikni</a></h4>


              <div className="indent">
                  <p>
                    {campIntroduction.videoNote}
                  </p>

                  <div className="responsive left">
                  {
                    campIntroduction.videoYoutubeIds.map((videoId, index) => (
                      <div className={"column-" + ((index % 3 ) + 1) }>
                        <YouTube
                          videoId={videoId}
                          opts={opts}
                          onReady={this._onReady}
                          />
                      </div>
                    ))
                   }
                  </div>
                </div>

                <PhotoGallery photos={getPhotosFromGallery(eventName, photoGallery)}/>

              </div>

            {hideHeaderAndFooter ? '' : <Footer />}
      </div>
    );
  }

}
